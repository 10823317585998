import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import OrdersTable from '../OrdersTable';
import React from 'react';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';

export default function OrdersWidget() {
	const {
		store = {},
		status,
		handleDelete,
	} = useSupercore({
		supercoreApiName: SCModules.orders,
	});

	return (
		<Card>
			<CardBody>
				<OrdersTable
					store={store}
					status={status}
					handleDelete={handleDelete}
				/>
			</CardBody>
		</Card>
	);
}
