import React from 'react';
import PropTypes from 'prop-types';
import Lang from '../../variables/i18n';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { concatSchool, formatName } from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import SCLink from '../SCLink';
import { SCModules } from '../supercore/api';
import useSupercore from '../useSupercore';

export default function OrderBrief({ orderId, show = true }) {
	const { store, status } = useSupercore({
		supercoreApiName: SCModules.orders,
		refreshData: !!orderId,
		entryId: orderId,
	});

	const {
		firstName,
		lastName,
		schoolId,
		schoolName,
		deptName,
		instName,
		eventDate,
		eventId,
	} = store[orderId] || {};

	return (
		show &&
		(status === 'success' ? (
			<TableContainer component={Paper}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{Lang.school}</TableCell>
							<TableCell align={'right'}>
								<SCLink
									module={SCModules.schools}
									entryId={schoolId}
									label={concatSchool({ instName, deptName, schoolName })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{Lang.eventDate}</TableCell>
							<TableCell align={'right'}>
								<SCLink
									module={SCModules.events}
									entryId={eventId}
									label={eventDate}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{Lang.customer}</TableCell>
							<TableCell align={'right'}>
								{formatName({ firstName, lastName })}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		) : (
			<CircularProgress />
		))
	);
}

OrderBrief.propTypes = {
	show: PropTypes.bool,
	orderId: PropTypes.string,
};
