import { SystemProperties } from '../variables/sysSettings';

export const concatSchool = ({
	shortName,
	instName,
	schoolName,
	name,
	deptName,
}) => {
	const res =
		shortName || [instName, schoolName, deptName || name].join(', ').trim();
	return res === ', ,' ? '' : res;
};

export const formatName = ({ firstName, lastName }) =>
	[lastName, firstName]
		.filter((o) => !!o)
		.map((s) => s.toLocaleUpperCase())
		.join();

export const hashCode = (str = '') => {
	let hash = 0,
		i,
		chr;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return Math.abs(hash);
};

export const parseDate = (firebaseDate) =>
	parseToDate(firebaseDate).toLocaleDateString(
		SystemProperties.defaultLocale.val
	);

export const parseDateAndTime = (firebaseDate) => {
	const dateObj = parseToDate(firebaseDate);
	return `${dateObj.toLocaleDateString(
		SystemProperties.defaultLocale.val
	)} ${dateObj.toLocaleTimeString(SystemProperties.defaultLocale.val)}`;
};

export const parseTimestamp = (firebaseDate) =>
	parseToDate(firebaseDate).getTime();

export const parseToDate = (firebaseDate = {}) =>
	typeof firebaseDate === 'string'
		? Date.parse(firebaseDate)
		: new Date(
				typeof firebaseDate === 'object'
					? (firebaseDate || {})._seconds * 1000
					: firebaseDate
		  );
