import React from 'react';
import PostsList from '../../components/PostsList/PostsList';
import ContentEditor from '../../components/ContentEditor';
import { Edit } from '@material-ui/icons';

const ContentRoute = {
	icon: Edit,
	path: 'content',
	name: 'Content',
	children: [
		{ path: '/', element: <PostsList showActions /> },
		{ path: 'create', element: <ContentEditor create /> },
		{ path: 'edit/:postId', element: <ContentEditor /> },
	],
};
export default ContentRoute;
