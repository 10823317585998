import React from 'react';
import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import PropTypes from 'prop-types';
import { concatSchool } from '../../utils';
import ComboBox from '../ComboBox';

export default function SchoolSelect({
	id,
	onChange,
	required = false,
	labelText = 'Επιλογή τμήματος',
	disabled = false,
	ignoreNoEvents = true,
	value,
	// ignoreNoEvents = true,
}) {
	const { store = {}, status } = useSupercore({
		supercoreApiName: SCModules.schools,
	});

	const schools = asArray(store)
		.filter(({ eventsCount = 0 }) => ignoreNoEvents || eventsCount)
		.sort((a, b) => concatSchool(a).localeCompare(concatSchool(b)));

	return (
		<ComboBox
			id={id}
			options={schools}
			onChange={(e, val) => {
				console.log(e);
				console.log(val);
				onChange({ target: { value: val ? val.id : undefined } });
			}}
			getOptionLabel={(option) => concatSchool(option)}
			// getOptionSelected={({id} ) => console.log(id) || id === value}
			value={store[value] || ''}
			label={labelText}
			fullWidth
			autoSelect
			blurOnSelect
			loading={status === 'pending'}
			required={required}
			disabled={disabled || status === 'pending'}
			groupBy={({ instName }) => instName}
		/>
	);
}

SchoolSelect.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onlyInstitution: PropTypes.bool,
	onlySchool: PropTypes.bool,
	disabled: PropTypes.bool,
	ignoreNoEvents: PropTypes.bool,
};
