import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@material-ui/pickers';

/*
export const DatePicker = ({ onChange, value, ...props }) => (
	<KeyboardDatePicker
		onChange={onChange}
		margin="normal"
		labelText="Ημερομηνία"
		label="Ημερομηνία"
		disableToolbar
		value={value || ''}
		{...props}
	/>
);

DatePicker.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.any,
	// value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

*/

export const TimePickerEl = ({ onChange, value, ...props }) => (
	<TimePicker
		onChange={onChange}
		showTodayButton
		todayLabel="now"
		label="Ώρα"
		margin="normal"
		minutesStep={5}
		value={value}
		{...props}
	/>
);

TimePickerEl.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.any,
};
