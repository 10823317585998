import { Dimension } from '../../variables/general';
import SchoolSelect from '../SchoolSelect';
import EventSelect from '../EventSelect';
import React from 'react';

export default function schoolEventPanel({
	form,
	setForm,
	schoolPickerId = 'schoolId',
	eventPickerId = 'eventId',
}) {
	return [
		{
			id: schoolPickerId,
			size: Dimension.formItem,
			renderElement: () => (
				<SchoolSelect
					required
					id={schoolPickerId}
					value={form[schoolPickerId]}
					onChange={({ target }) =>
						setForm({ ...form, [schoolPickerId]: target.value })
					}
				/>
			),
		},
		{
			id: eventPickerId,
			size: Dimension.formItem,
			renderElement: () => (
				<EventSelect
					required
					id={eventPickerId}
					filterBySchoolId={form[schoolPickerId]}
					value={form[eventPickerId]}
					onChange={({ target }) =>
						setForm({ ...form, [eventPickerId]: target.value })
					}
				/>
			),
		},
	];
}
