import DashboardRoute from './views/Dashboard/Dashboard.js';
import React from 'react';
import ContentRoute from './views/Content/Content';
import Admin from './layouts/Admin';
import Login from './layouts/Login';
import Register from './layouts/Register';
import { Navigate } from 'react-router';
import { SystemProperties } from './variables/sysSettings';
import VaultRoute from './views/VaultView/VaultView';
import OrdersRoute from './views/Orders/OrdersView';
import EventsRoute from './views/EventsView/EventsView';
import SchoolsRoute from './views/SchoolsView/SchoolsView';
import PhotobooksRoute from './views/Phb/Photobooks';
import UsersRoute from './views/Users/UsersView';

const dashboardRoutes = [
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: SystemProperties.dashboardRootPath.val,
		element: <Admin />,
		children: [
			DashboardRoute,
			VaultRoute,
			PhotobooksRoute,
			OrdersRoute,
			EventsRoute,
			SchoolsRoute,
			UsersRoute,
			ContentRoute,
			// SystemRoute,
		],
	},
	{
		path: '/',
		element: (
			<Navigate to={`${SystemProperties.dashboardRootPath.val}/dashboard`} />
		),
	},
];

export default dashboardRoutes;
