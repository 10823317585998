// import 'date-fns';
import React, { useState } from 'react';
import { AppContext, useInitialiseStore } from './store/store';
import useCurrentUser from './components/Firebase/Firebase';
import { initApi } from './components/supercore/api';
import useUsers from './store/users';
import { useRoutes } from 'react-router';
import dashboardRoutes from './routes';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StoreContext } from './components/useSupercore';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
	const [error, setError] = useState(null);
	const { state, dispatch } = useInitialiseStore();
	const [user, setUser, firebase] = useCurrentUser();
	const [usersState, usersReducer] = useUsers();
	initApi(firebase);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<AppContext.Provider
				value={{
					error,
					setError,
					firebase,
					user,
					setUser,
					usersState,
					usersReducer,
				}}
			>
				<StoreContext.Provider
					value={{
						state,
						dispatch,
					}}
				>
					<BrowserRouter>
						<InnerRouter />
					</BrowserRouter>
				</StoreContext.Provider>
			</AppContext.Provider>
		</MuiPickersUtilsProvider>
	);
};

const InnerRouter = () => {
	return useRoutes(dashboardRoutes);
};

export default App;
