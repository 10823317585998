import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
// @material-ui/icons
// core components
import styles from './../../assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import { Actions, AppContext } from '../../store/store';
import ExpandableWidget from './ExpandableWidget';
import useCurrentUser from '../Firebase/Firebase';
import UserAvatar from '../UserAvatar';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';

const useStyles = makeStyles(styles);

export default function ProfileWidget() {
	const classes = useStyles();
	// const navigate = useNavigate();

	const { firebase } = useContext(AppContext);
	const [user] = useCurrentUser();
	const { dispatch } = useSupercore({ supercoreApiName: SCModules.users });

	const logoutHandler = () => {
		dispatch({ type: Actions.INIT_STORE });
		firebase.signOutUser();
	};

	return (
		<ExpandableWidget
			label="Profile"
			id="profile-menu-list-grow"
			icon={<UserAvatar uid={(user || {}).uid} showName={false} />}
		>
			{/*			<MenuItem className={classes.dropdownItem}>Profile</MenuItem>
			<MenuItem
				onClick={() => navigate('/admin/profile')}
				className={classes.dropdownItem}
			>
				Settings
			</MenuItem>
			<Divider light />*/}
			<MenuItem onClick={logoutHandler} className={classes.dropdownItem}>
				Logout
			</MenuItem>
		</ExpandableWidget>
	);
}
