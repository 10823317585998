import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Lang from '../../variables/i18n';
import IconButton from '@material-ui/core/IconButton';
import { Delete, MoreVert, Visibility } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import { SystemProperties } from '../../variables/sysSettings';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const CustomAction = forwardRef(
	(
		{
			show = true,
			label,
			Icon,
			onClick,
			disabled = false,
			onComplete,
			handleClose,
		},
		ref
	) =>
		!show ? null : (
			<MenuItem
				disabled={disabled}
				ref={ref}
				onClick={() =>
					handleClose() | onClick() &&
					typeof onComplete === 'function' &&
					onComplete()
				}
			>
				{Icon && (
					<ListItemIcon>
						<Icon fontSize="small" />
					</ListItemIcon>
				)}
				<Typography variant="inherit">{label}</Typography>
			</MenuItem>
		)
);

/**
 *
 * @param {string} id Entity ID
 * @param {bool} view
 * @param {string} viewURL
 * @param {function?} handleDelete
 * @param {function?} onDeleteComplete
 * @param {boolean?} deleteDisabled
 * @param elBefore
 * @param actions
 * @returns {JSX.Element}
 * @constructor
 */
export default function GenericActions({
	id,
	view,
	viewURL,
	handleDelete,
	onDeleteComplete,
	deleteDisabled = false,
	elBefore,
	actions = [],
}) {
	const navigate = useNavigate();
	const [anchor, setAnchor] = useState(null);

	const handleClick = ({ currentTarget }) => setAnchor(currentTarget);
	const handleClose = () => setAnchor(null);

	const doView = () =>
		navigate(
			viewURL
				? `/${SystemProperties.dashboardRootPath.val}/${viewURL}`
				: typeof view === 'boolean'
				? `view/${id}`
				: `/${SystemProperties.dashboardRootPath.val}/${view}/view/${id}`,
			{ replace: !!viewURL }
		);

	const allActions = [...actions];

	view &&
		allActions.unshift({ label: Lang.info, Icon: Visibility, onClick: doView });
	handleDelete &&
		allActions.push({
			label: Lang.delete,
			Icon: Delete,
			onClick: handleDelete,
			onComplete: onDeleteComplete,
			disabled: deleteDisabled,
		});

	return (
		<span style={{ display: 'flex' }}>
			{elBefore}
			{view && (
				<Tooltip title={Lang.info}>
					<IconButton onClick={doView}>
						<Visibility />
					</IconButton>
				</Tooltip>
			)}

			<IconButton
				aria-label="actions"
				edge="end"
				color="inherit"
				onClick={handleClick}
			>
				<MoreVert />
			</IconButton>
			<Menu
				keepMounted
				id={`menu_${id}`}
				anchorEl={anchor}
				onClose={handleClose}
				open={Boolean(anchor)}
			>
				{allActions.map((action, i) => (
					<CustomAction
						key={`action_${i}`}
						{...action}
						handleClose={handleClose}
					/>
				))}
			</Menu>
		</span>
	);
}

CustomAction.propTypes = {
	show: PropTypes.bool,
	label: PropTypes.string.isRequired,
	Icon: PropTypes.any,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	onComplete: PropTypes.func,
	handleClose: PropTypes.func.isRequired,
};

GenericActions.propTypes = {
	id: PropTypes.string.isRequired,
	view: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	viewURL: PropTypes.string,
	handleDelete: PropTypes.func,
	onDeleteComplete: PropTypes.func,
};
