import React from 'react';
import GenericForm from '../GenericForm';
import { Dimension } from '../../variables/general';
import ItemsList from '../ItemsList';
import { CopyFromAboveButton } from '../CommonButtons';

/**
 * Add new element to list
 * @param inputId
 * @param formState
 * @param setFormState
 */
const photoListOnClick = (inputId, formState, setFormState) => {
	const el = document.getElementById(inputId);
	const val = (el.value || '').split(/\s+/).filter((s) => s.length);

	if (val.length === 0) {
		return;
	}

	setFormState({
		...formState,
		[inputId]: [...new Set([...(formState[inputId] || []), ...val])],
	});

	el.value = '';
};

const copyListFromAbove = ({ formState, setFormState }) => {
	const photoListId = 'photosList';
	const photoListPhbId = 'photosList_phb';

	setFormState({
		...formState,
		[photoListPhbId]: formState[photoListId] || [],
	});
};

export default function OrderExtrasForm({
	formState,
	setFormState,
	create = false,
}) {
	const { phbSize = 'none' } = formState;

	const fields = [
		{
			id: 'fldvd',
			type: 'formLabel',
			label: 'DVD',
		},
		{
			id: 'isDvd',
			type: 'checkbox',
			label: 'DVD',
			size: Dimension.formItemFullWidth,
		},
		{
			id: 'extradvd',
			type: 'textarea',
			label: 'Παρατηρήσεις DVD',
			size: Dimension.formItemFullWidth,
		},
		{
			id: 'flphb',
			type: 'formLabel',
			label: 'Photobook',
		},
		{
			id: 'phbSize',
			type: 'radio',
			label: 'Μέγεθος',
			options: [
				{
					label: 'Κανένα',
					value: 'none',
					selected: phbSize === 'none',
				},
				{
					label: '20x20 (Μικρό)',
					value: '20x20',
					selected: phbSize === '20x20',
				},
				{
					label: '20x30 (Μεγάλο)',
					value: '20x30',
					selected: phbSize === '20x30',
				},
			],
		},
		{
			id: 'photosList_phb',
			size: Dimension.formItemFullWidth,
			renderElement: () => (
				<ItemsList
					key={`photosList_phb-${(formState['photosList'] || []).length}`}
					copyToClipboard
					id={'photosList_phb'}
					formState={formState}
					setFormState={setFormState}
					onClick={() =>
						photoListOnClick('photosList_phb', formState, setFormState)
					}
					labelText={'Αριθμός φωτογραφίας για Photobook'}
					emptyText={
						'Αφήστε το κενό αν η λίστα του Photobook είναι ίδια με της παραγγελίας'
					}
					extraButton={
						<CopyFromAboveButton
							disabled={!(formState['photosList'] || []).length}
							onClick={() => copyListFromAbove({ formState, setFormState })}
						/>
					}
				/>
			),
		},
		{
			id: 'extra',
			type: 'textarea',
			label: 'Παρατηρήσεις Photobook',
			placeholder: 'Σχόλια ειδικά για το Photobook',
			size: Dimension.formItemFullWidth,
		},
	].concat(
		create
			? []
			: [
					{
						id: 'sentDate',
						label: 'Ημ/νια αποστολής',
						placeholder: 'Επιλέξτε άτομο',
						value: formState.sentDate
							? new Date(formState.sentDate._seconds * 1000)
							: '',
						shrink: true,
						disabled: true,
					},
					{
						id: 'sentBy',
						label: 'Εστάλη απο',
						type: 'userpicker',
					},
					{
						id: 'receivedDate',
						label: 'Ημ/νια παραλαβής',
						placeholder: 'Επιλέξτε άτομο',
						value: formState.receivedDate
							? new Date(formState.receivedDate._seconds * 1000)
							: '',
						shrink: true,
						disabled: true,
					},
					{
						id: 'receivedBy',
						label: 'Παραλήφθηκε απο',
						type: 'userpicker',
					},
			  ]
	);

	return (
		<GenericForm
			setFormState={setFormState}
			formState={formState}
			formLabel={'Δώρα'}
			fields={fields}
		/>
	);
}
