import { AccountBalance, Euro } from '@material-ui/icons';
import React from 'react';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Lang from '../../variables/i18n';
import CardFooter from '../../components/Card/CardFooter';
import { CreateButton } from '../../components/CommonButtons';
import { Navigate } from 'react-router';
import TransactionDetails from '../../components/TransactionDetails';
import TransactionsTable from '../../components/TransactionsTable';
import CardTitle from '../../components/CardTitle';

const VaultView = () => {
	return (
		<Card>
			<CardHeader>
				<CardTitle title={VaultRoute.name} icon={<AccountBalance />} />
			</CardHeader>
			<CardBody>
				<TransactionsTable />
			</CardBody>
			<CardFooter>
				<span />
				<CreateButton icon={<Euro />} title={Lang.vaultView.newTransaction} />
			</CardFooter>
		</Card>
	);
};

const VaultRoute = {
	path: 'cash',
	name: Lang.vaultView.title,
	icon: AccountBalance,
	children: [
		{ path: '/', element: <VaultView /> },
		{ path: '/create', element: <TransactionDetails create /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:transactionId', element: <TransactionDetails /> },
	],
};

export default VaultRoute;
