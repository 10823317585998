import React from 'react';
import CountUp from 'react-countup';
import { makeStyles } from '@material-ui/core';
import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import useSupercore, { useStore } from '../useSupercore';
import { SCModules } from '../supercore/api';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import { Euro } from '@material-ui/icons';
import Lang from '../../variables/i18n';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(styles);

export default function VaultWidget({ shift, month }) {
	const classes = useStyles();
	const now = new Date();
	const year = now.getFullYear();
	const thisMonth = now.getMonth() + 1;
	const day = now.getDate();
	const shiftId = month
		? `${year}-${thisMonth}`
		: `${year}-${thisMonth}-${day}-${shift}`;

	const { state: store } = useStore();

	const { status } = useSupercore({
		supercoreApiName: SCModules.vaultTotals,
		entryId: shiftId,
	});

	const data = (store[SCModules.vaultTotals] || {})[shiftId] || {};

	return (
		<Card>
			<CardHeader color="primary" stats icon>
				<CardIcon color="primary">
					<Euro />
				</CardIcon>
				<p className={classes.cardCategory}>
					{shift ? `Σήμερα - Βάρδια ${shift}` : 'Μήνας'}
				</p>
				<h3 className={classes.cardTitle}>
					{status === 'pending' ? (
						<CircularProgress />
					) : (
						<>
							<small>{Lang.currencySymbol.euro}</small>
							<CountUp duration={0.4} start={0} end={data.amountTotal || 0} />
						</>
					)}
				</h3>
			</CardHeader>
		</Card>
	);
}
