export const Dimension = {
	md: {
		xs: 12,
		sm: 12,
		md: 10,
	},
	taskColumn: {
		xs: 3,
	},
	formItem: {
		xs: 6,
	},
	formItemOneThird: {
		xs: 4,
	},
	formItemTwoThirds: {
		xs: 8,
	},
	formItemFullWidth: {
		xs: 12,
	},
};
