import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import { BackButton, CreateButton, SaveButton } from '../CommonButtons';
import Card from '../Card/Card';
import GenericForm from '../GenericForm';
import FormLabel from '@material-ui/core/FormLabel';
import { genericAddressFields } from '../GenericAddressForm';
import { Event, School, ShoppingBasket } from '@material-ui/icons';
import { Dimension } from '../../variables/general';
import * as yup from 'yup';
import GenericBackdrop, { useBackdrop } from '../GenericBackdrop';
import CardTitle from '../CardTitle';
import { concatSchool } from '../../utils';
import { ChipContainer } from '../CommonChips';
import Chip from '@material-ui/core/Chip';
import Lang from '../../variables/i18n';

export default function SchoolDetails({ create = false }) {
	const { eventId: entryId } = useParams();
	const [isValid, setIsValid] = useState(false);

	const { store, status, handleSave, handleCreate, handleDelete } =
		useSupercore({
			create,
			supercoreApiName: SCModules.schools,
			entryId: entryId,
		});

	const [data, setData] = useState({});
	const [pending, setPending] = useBackdrop(status);

	const { eventsCount = 0, ordersCount = 0 } = data;

	useEffect(() => {
		!create && setData(store[entryId] || {});
	}, [create, setData, store, entryId, status]);

	return (
		<Card>
			<CardHeader>
				<CardTitle
					id={entryId}
					icon={<School />}
					title={create ? 'Δημιουργία Σχολής' : `Σχολή ${concatSchool(data)}`}
					handleDelete={handleDelete}
				/>
				<ChipContainer show={!create}>
					<Chip
						icon={<Event />}
						variant={'outlined'}
						color={'primary'}
						label={`${Lang.events}: ${eventsCount}`}
					/>
					<Chip
						icon={<ShoppingBasket />}
						variant={'outlined'}
						color={'primary'}
						label={`${Lang.orders}: ${ordersCount}`}
					/>
				</ChipContainer>
			</CardHeader>
			<CardBody>
				{create && (
					<React.Fragment>
						<FormLabel>Δημιουργία νέου Τμήματος</FormLabel>
						<p>
							Το κάθε Τμήμα υπάγεται σε μια Σχολή, που υπάγεται σε ενα Ίδρυμα
						</p>
					</React.Fragment>
				)}
				<GenericBackdrop open={pending} />
				<GenericForm
					setIsValid={setIsValid}
					formState={data}
					setFormState={setData}
					fields={[
						{
							id: 'shortName',
							label: 'Συντομονυμία',
							placeholder: 'Σύντομο όνομα',
							size: Dimension.formItemOneThird,
							readOnly: false,
							yup: yup.string(),
						},
						{
							id: 'sep',
							type: 'formLabel',
							label: '',
						},
						{
							id: 'instName',
							label: 'Ίδρυμα',
							placeholder: 'Όνομα ιδρύματος, πχ. "ΑΠΘ"',
							size: Dimension.formItemOneThird,
							readOnly: !create,
							yup: yup.string().required(),
						},
						{
							id: 'schoolName',
							label: 'Σχολή',
							placeholder: 'Όνομα σχολής, πχ. "Νομική"',
							size: Dimension.formItemOneThird,
							readOnly: !create,
							yup: yup.string().required(),
						},
						{
							id: 'name',
							label: 'Τμήμα',
							placeholder: 'Όνομα τμήματος, πχ. "Διεθνών Σπουδών"',
							size: Dimension.formItemOneThird,
							readOnly: !create,
							yup: yup.string().required(),
						},
						{
							id: 'sl1',
							type: 'formLabel',
							label: 'Διεύθυνση Τμήματος',
						},
						...genericAddressFields,
					]}
				/>
			</CardBody>

			<CardFooter>
				<BackButton />
				{create ? (
					<CreateButton
						isValid={isValid}
						icon={<School />}
						onClick={() => handleCreate(data)}
						onPending={setPending}
					/>
				) : (
					<SaveButton
						onPending={setPending}
						isValid={isValid}
						onClick={() => handleSave(data)}
					/>
				)}
			</CardFooter>
		</Card>
	);
}
