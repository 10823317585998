import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import React from 'react';
import Select from '@material-ui/core/Select/Select';
import { customInputTableStyle } from '../../assets/jss/material-dashboard-react/components/customInputStyle';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(customInputTableStyle);

export default function EventSelect({
	id,
	value,
	label = 'Επιλογή ορκομωσίας',
	onChange,
	required = false,
	filterBySchoolId,
}) {
	const filter = filterBySchoolId ? `schoolId/${filterBySchoolId}` : undefined;
	const classes = useStyles();
	const { store, status } = useSupercore({
		supercoreApiName: SCModules.events,
		entryId: filter,
	});

	const events = asArray(store)
		.filter(
			({ schoolId }) => !filterBySchoolId || filterBySchoolId === schoolId
		)
		.sort(
			({ eventDate: eA = {} }, { eventDate: eB = {} }) =>
				eA._seconds - eB._seconds
		);

	label = required ? (
		<>
			<span style={{ color: 'red' }}>*</span> {label}
		</>
	) : (
		label
	);

	return (
		<FormControl
			fullWidth
			className={classes.formControl}
			disabled={status !== 'success'}
		>
			<InputLabel id={`label_${id}`}>{label}</InputLabel>
			<Select
				id={id}
				labelId={`label_${id}`}
				value={value || ''}
				onChange={onChange}
			>
				{events.length ? (
					events
						.map(({ id, date = {} }) => ({
							id,
							date: new Date(
								typeof date === 'number' ? date : (date || {})._seconds * 1000
							),
						}))
						.map(({ id, date }) => (
							<MenuItem selected={value === id} key={id} value={id}>
								{date.toLocaleDateString('el-GR')}
							</MenuItem>
						))
				) : (
					<MenuItem disabled>
						Δεν υπάρχουν ορκομωσίες για αυτή τη σχολή
					</MenuItem>
				)}
			</Select>
		</FormControl>
	);
}
