import app from 'firebase/app';
// import 'firebase/auth';
import { firebaseConfig } from '../../firebase.config';
import { useEffect, useState } from 'react';

export class Firebase {
	constructor(setUser) {
		if (!app.apps.length) {
			app.initializeApp(firebaseConfig);
		}

		this.auth = app.auth();
		this.auth.onAuthStateChanged((user) => setUser(user));
		if (`${window.location}`.indexOf('http://localhost') === 0) {
			this.auth.useEmulator('http://localhost:9099');
		}
	}

	registerNewUser = async (email, password, displayName) => {
		try {
			const response = await fetch(
				`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/auth-users`,
				{
					method: 'POST',
					mode: 'no-cors',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: email,
						password: password,
						displayName: displayName,
					}),
				}
			);

			return response.ok;
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	signInUser = (email, password) => () =>
		this.auth.signInWithEmailAndPassword(email, password);

	signOutUser = () => this.auth.signOut().then(() => localStorage.clear());

	resetPassword = (email) => this.auth.sendPasswordResetEmail(email);

	updatePassword = (password) => this.auth.currentUser.updatePassword(password);

	getCurrentUser = () => this.auth.currentUser;

	getToken = async () =>
		await (this.auth.currentUser && this.auth.currentUser.getIdToken(true));
}

const useCurrentUser = () => {
	const [user, setUser] = useState();
	const firebase = new Firebase(setUser);

	useEffect(() => setUser(firebase.getCurrentUser()), [user, firebase]);

	return [user, setUser, firebase];
};

export default useCurrentUser;
