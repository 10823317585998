import React from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CustomInput from '../../components/CustomInput/CustomInput';
import PropTypes from 'prop-types';

export default function TextEditor({ body = '', onChange, inputProps }) {
	return (
		<Card>
			<CardBody>
				<CustomInput
					labelText="Content"
					id="text-body"
					value={body}
					onChange={(e) => onChange(e.target.value)}
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						multiline: true,
						rows: 15,
						...inputProps,
					}}
				/>
			</CardBody>
		</Card>
	);
}

TextEditor.propTypes = {
	body: PropTypes.string,
	inputProps: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};
