import React, { useState } from 'react';
import { useParams } from 'react-router';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import { Dimension } from '../../variables/general';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import CardHeader from '../Card/CardHeader';
import Card from '../Card/Card';
import CardFooter from '../Card/CardFooter';
import { CancelButton, CreateButton, SaveButton } from '../CommonButtons';
import CardBody from '../Card/CardBody';
import { SCModules } from '../supercore/api';
import GenericForm from '../GenericForm';
import useSupercore from '../useSupercore';
import { Event } from '@material-ui/icons';
import SchoolSelect from '../SchoolSelect';
import Lang from '../../variables/i18n';
import * as yup from 'yup';
import GenericBackdrop, { useBackdrop } from '../GenericBackdrop';
import CardTitle from '../CardTitle';
import { concatSchool, parseDate } from '../../utils';

const InitialData = {
	date: new Date(),
};

const parseIncomingData = ({ date = {}, ...rest } = {}) => ({
	date: new Date(date._seconds * 1000),
	...rest,
});

export default function EventDetails({ create = false }) {
	const autocompleteId = 'schoolId';
	const { eventId } = useParams();
	const [isValid, setIsValid] = useState(false);
	const [pending, setPending] = useBackdrop();
	const [form, setForm] = useState(InitialData);
	const { status, handleCreate, handleSave, handleDelete } = useSupercore({
		create,
		supercoreApiName: SCModules.events,
		entryId: eventId,
		setForm: (data) => setForm(parseIncomingData(data)),
	});

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<CardHeader>
						<CardTitle
							id={eventId}
							create={create}
							icon={<Event />}
							handleDelete={handleDelete}
							createTitle={Lang.eventDetails.createTitle}
							editTitle={`${Lang.eventDetails.title} ${concatSchool(
								form
							)} ${parseDate(form.date)}`}
						/>
					</CardHeader>
					<CardBody>
						<GenericBackdrop open={pending} />
						<GenericForm
							setFormState={setForm}
							formState={form}
							setIsValid={setIsValid}
							fields={[
								{
									id: 'l1',
									type: 'formLabel',
									label: Lang.eventDetails.labelSchoolAndDate,
								},
								{
									id: 'date',
									type: 'datepicker',
									size: Dimension.formItemOneThird,
									required: true,
								},
								{
									id: autocompleteId,
									size: Dimension.formItemTwoThirds,
									yup: yup.string().required(),
									renderElement: () => (
										<SchoolSelect
											ignoreNoEvents={true}
											id={autocompleteId}
											value={form[autocompleteId]}
											onChange={({ target }) => {
												console.log(target.value);
												setForm({ ...form, [autocompleteId]: target.value });
											}}
										/>
									),
								},
								{
									id: 'sl1',
									type: 'formLabel',
									label: Lang.eventDetails.labelEventStatistics,
								},
								{
									id: 'numberPeople',
									label: Lang.eventDetails.numberPeople,
									placeholder: Lang.eventDetails.numberPeoplePlaceholder,
									yup: yup.number().min(0),
									required: true,
									type: 'number',
								},
								{
									id: 'numberPhotos',
									label: Lang.eventDetails.numberPhotos,
									placeholder: Lang.eventDetails.numberPhotosPlaceholder,
									yup: yup.number().min(0),
									required: true,
									type: 'number',
								},
								{
									id: 'otherStudios',
									label: Lang.eventDetails.otherStudios,
									placeholder: Lang.eventDetails.otherStudiosPlaceholder,
									yup: yup.string().min(0),
									type: 'textarea',
								},
							]}
						/>
					</CardBody>
					<CardFooter>
						<CancelButton />
						{create ? (
							<CreateButton
								icon={<Event />}
								isValid={isValid}
								disabled={pending || status === 'pending'}
								onPending={setPending}
								onClick={() => {
									handleCreate(form);
								}}
							/>
						) : (
							<SaveButton
								disabled={status === 'pending'}
								isValid={isValid}
								onPending={setPending}
								onClick={() => {
									handleSave(form);
								}}
							/>
						)}
					</CardFooter>
				</Card>
			</GridItem>
			<GridItem {...Dimension.md}>
				<GenericAdditionalInfo
					show={!create}
					collection={'events'}
					id={eventId}
				/>
			</GridItem>
		</GridContainer>
	);
}
