import React from 'react';
import PropTypes from 'prop-types';
import CustomTable from '../Table/Table';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import Lang from '../../variables/i18n';
import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import UserAvatar from '../UserAvatar';
import TimeField from '../TimeField/TimeField';
import {
	CreateActionChip,
	DeleteActionChip,
	EditActionChip,
} from '../CommonChips';

export default function AuditHistory({ id }) {
	const { store = {}, status } = useSupercore({
		supercoreApiName: SCModules.history,
		entryId: `id/${id}`,
	});

	const historyRaw = asArray(store)
		.filter(({ id: entryId }) => entryId === id)
		.sort(
			({ timestamp: tA = {} }, { timestamp: tB = {} }) =>
				tA._seconds - tB.seconds
		)
		.map(({ action, timestamp, uid }) => [
			<UserAvatar uid={uid} showName />,
			<TimeField datetime={timestamp} />,
			action === 'create' ? (
				<CreateActionChip />
			) : action === 'delete' ? (
				<DeleteActionChip />
			) : (
				<EditActionChip />
			),
		]);

	return (
		<Card>
			<CardBody>
				<CustomTable
					pending={status === 'pending'}
					tableHead={[Lang.user, Lang.date, Lang.action]}
					tableData={historyRaw}
				/>
			</CardBody>
		</Card>
	);
}

AuditHistory.propTypes = {
	id: PropTypes.string.isRequired,
};
