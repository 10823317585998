import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Lang from '../../variables/i18n';
import TransactionForm from '../TransactionForm';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import { TxInitialData } from '../TransactionDetails';
import PropTypes from 'prop-types';

export default function PaymentModal({
	open = false,
	orderId,
	onClose,
	create = false,
	id,
}) {
	const {
		store,
		// status,
		handleCreate,
	} = useSupercore({
		create,
		supercoreApiName: SCModules.vault,
	});

	const orderData = store[id] || {};

	const [form, setForm] = useState({});

	useEffect(() => {
		setForm({
			...form,
			...TxInitialData,
			...orderData,
			orderId,
			...(store || {})[id],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId]);

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{Lang.newPayment}</DialogTitle>
			<DialogContent>
				<TransactionForm form={form} setForm={setForm} orderId={orderId} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>{Lang.cancel}</Button>
				<Button
					onClick={() => handleCreate(form, false, true) && onClose()}
					color="primary"
				>
					{Lang.save}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

PaymentModal.propTypes = {
	orderId: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	create: PropTypes.bool,
};
