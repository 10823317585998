import { SystemProperties } from '../../variables/sysSettings';
import Link from '../Link';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Used for linking inside Supercore.
 * @param {boolean?} back - Default false. Overrides other options and goes to '..'
 * @param {string} label - Text to display
 * @param {string} module - Supercore module
 * @param {string?} entryId - Entry ID
 * @param {*?} props - Optional passed to internal Link component
 * @returns {*}
 * @constructor
 */
export default function SCLink({
	back = false,
	label,
	module,
	entryId,
	...props
}) {
	let to = SystemProperties.dashboardRootPath.val;

	to = module ? `${to}/${module.split('-', 2)[0]}` : to;
	to = entryId ? `${to}/view/${entryId}` : to;
	to = back ? '..' : to;

	return (
		<Link to={to} {...props}>
			{typeof label === 'object' && label.hasOwnProperty('_seconds')
				? new Date(label._seconds * 1000).toLocaleDateString(
						SystemProperties.defaultLocale.val
				  )
				: label}
		</Link>
	);
}

SCLink.propTypes = {
	back: PropTypes.bool,
	label: PropTypes.any.isRequired,
	module: PropTypes.string,
	entryId: PropTypes.string,
};
