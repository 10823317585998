import { useReducer } from 'react';
import { validateEmail } from '../../layouts/utils';

export const actions = {
	UPDATE_LOGIN_FORM: 'UPDATE_LOGIN_FORM',
	UPDATE_REGISTER_FORM: 'UPDATE_REGISTER_FORM',
	SET_ERROR: 'SET_ERROR',
	REGISTER_SUCCESS: 'REGISTER_SUCCESS',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	DO_LOGIN: 'DO_LOGIN',
	DO_LOGIN_COMPLETE: 'DO_LOGIN_COMPLETE',
};

const initialState = {
	registerEmail: '',
	registerDisplayName: '',
	registerPass: '',
	registerPass2: '',

	registerEmailValid: true,
	registerPassValid: true,
	registerPass2Valid: true,
	error: false,
};

const getEl = (id) => document.getElementById(id);

const validateEmailField = (
	id //if the element has focus, the user is probably still typing
) =>
	getEl(id).value === initialState.registerEmail ||
	document.activeElement.id === id
		? true
		: validateEmail(getEl(id).value);

const validatePassword = (id) =>
	document.activeElement.id === id ||
	getEl(id).value === initialState[id] ||
	getEl(id).value.length >= 8;

function reducer(state, action) {
	const { type, data } = action;

	switch (type) {
		case actions.UPDATE_LOGIN_FORM:
			return {
				...state,
				loginUser: getEl('userEmail').value,
				loginPass: getEl('userPassword').value,
			};

		case actions.UPDATE_REGISTER_FORM:
			const passValid = validatePassword('password');
			const pass2Valid =
				passValid &&
				(getEl('password').value.startsWith(getEl('passwordAgain').value) ||
					getEl('password').value === initialState.registerPass2);

			return {
				...state,
				registerEmail: getEl('email').value,
				registerDisplayName: getEl('displayName').value,
				registerPass: getEl('password').value,
				registerPass2: getEl('passwordAgain').value,
				registerEmailValid: validateEmailField('email'),
				registerPassValid: passValid,
				registerPass2Valid: pass2Valid,
				error:
					passValid && pass2Valid
						? null
						: {
								message:
									(!passValid && 'Must be at least 8 characters long') ||
									'Passwords do not match',
						  },
			};

		case actions.REGISTER_SUCCESS:
			return {
				...initialState,
				registerSuccess: true,
			};

		case actions.SET_ERROR:
			return {
				...state,
				error: data
					? {
							message: data,
					  }
					: initialState.error,
			};

		default:
			throw new Error('No action type defined');
	}
}

export default function useAuthState() {
	const [state, dispatch] = useReducer(reducer, initialState);
	let customDispatch = (action) => {
		if (typeof action === 'function') {
			action(customDispatch);
		} else {
			dispatch(action);
		}
	};
	return [state, customDispatch];
}
