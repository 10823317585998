import { ImportContacts } from '@material-ui/icons';
import React from 'react';
import { Navigate } from 'react-router';
import PhotobookDetails from '../../components/PhotobookDetails';
import useSupercore, { asArray } from '../../components/useSupercore';
import { SCModules } from '../../components/supercore/api';
import Lang from '../../variables/i18n';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CustomTable from '../../components/Table/Table';
import CardFooter from '../../components/Card/CardFooter';
import Card from '../../components/Card/Card';
import { concatSchool } from '../../utils';
import GenericActions from '../../components/GenericActions';
import Muted from '../../components/Typography/Muted';
import CardTitle from '../../components/CardTitle';
import SCLink from '../../components/SCLink';

const headers = [
	'Πελάτης',
	'Παραγγελία',
	'Σχολή',
	'Ορκομωσία',
	'Μέγεθος',
	'Κατάσταση',
	'', // action buttons
];

const getCustomerName = ({ firstName = '', lastName = '' }) =>
	`${lastName.toLocaleUpperCase()} ${firstName.toLocaleUpperCase()}`;

function PhotobooksView() {
	const { store, status, error } = useSupercore({
		supercoreApiName: SCModules.orders,
		initialData: [],
	});

	const entries = asArray(store)
		.filter(({ hasPhotobook }) => hasPhotobook)
		.map(
			({
				id,
				// status, //customer
				eventId,
				eventDate,
				schoolName,
				deptName,
				instName, //order
				phbSize,
				...args
			}) => [
				getCustomerName(args),
				<SCLink module={SCModules.orders} entryId={id} label={Lang.order} />,
				concatSchool({ schoolName, instName, name: deptName }),
				<SCLink
					module={SCModules.events}
					entryId={eventId}
					label={eventDate}
					to={`events/view/${eventId}`}
				/>,
				phbSize,
				'', //status
				<GenericActions
					id={id}
					viewURL={`/orders/view/${id}/photobook`}
					view
					handleDelete={() => {}}
				/>,
			]
		);

	return (
		<Card>
			<CardHeader>
				<CardTitle title={PhotobooksRoute.name} icon={<ImportContacts />} />
			</CardHeader>
			<CardBody>
				<CustomTable
					pending={status === 'pending'}
					empty={status === 'success' && entries.length === 0}
					error={error}
					tableHead={headers}
					tableData={entries}
				/>
			</CardBody>
			<CardFooter>
				<span />
				<Muted>Τα Photobooks δημιουργούνται μέσα απο τις Παραγγελίες</Muted>
			</CardFooter>
		</Card>
	);
}

const PhotobooksRoute = {
	path: 'photobooks',
	name: 'Photobooks',
	icon: ImportContacts,
	children: [
		{ path: '/', element: <PhotobooksView /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:entryId', element: <PhotobookDetails /> },
	],
};

export default PhotobooksRoute;
