import React from 'react';
import GridItem from './../../components/Grid/GridItem.js';
import GridContainer from './../../components/Grid/GridContainer.js';
import { Dashboard } from '@material-ui/icons';
import VaultWidget from '../../components/VaultWidget';
import OrdersWidget from '../../components/OrdersWidget';

function DashboardView() {
	return (
		<>
			<GridContainer>
				<GridItem xs={3}>
					<VaultWidget shift={1} />
				</GridItem>
				<GridItem xs={3}>
					<VaultWidget shift={2} />
				</GridItem>
				<GridItem xs={3}>
					<VaultWidget month />
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12}>
					<OrdersWidget />
				</GridItem>
			</GridContainer>
		</>
	);
}

const DashboardRoute = {
	path: 'dashboard',
	name: 'Dashboard',
	icon: Dashboard,
	element: <DashboardView />,
};

export default DashboardRoute;
