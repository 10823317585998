import { useParams } from 'react-router';
import CardHeader from '../Card/CardHeader';
import Card from '../Card/Card';
import React from 'react';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import { BackButton } from '../CommonButtons';
import PhotobookForm from '../OrderExtrasForm';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';

export default function PhotobookDetails() {
	const { entryId } = useParams();

	const { data: photobookForm, setData: setPhotobookForm } = useSupercore({
		supercoreApiName: SCModules.photobook,
		entryId,
		create: false,
	});

	return (
		<Card>
			<CardHeader>
				<h4>Photobook</h4>
			</CardHeader>
			<CardBody>
				<PhotobookForm
					setFormState={setPhotobookForm}
					formState={photobookForm}
				/>
			</CardBody>
			<CardFooter>
				<BackButton />
			</CardFooter>
		</Card>
	);
}
