import Lang from '../../variables/i18n';
import CustomTable from '../Table/Table';
import React, { useState } from 'react';
import { asArray } from '../useSupercore';
import { concatSchool, formatName } from '../../utils';
import GenericActions from '../GenericActions';
import { AddMoneyIcon } from '../../variables/AppIcons';
import DeleteModal from '../DeleteModal';
import OrderBrief from '../OrderBrief';
import PaymentModal from '../PaymentModal';
import SCLink from '../SCLink';
import { SCModules } from '../supercore/api';
import useForceRefresh from '../useForceRefresh';

export default function OrdersTable({ status, store = {}, handleDelete }) {
	const [deleteModal, setDeleteModal] = useState(undefined);
	const [txModal, setTxModal] = useState(undefined);
	const { setForceRefresh } = useForceRefresh();

	const entries = asArray(store).map(
		({
			id,
			schoolName,
			deptName,
			instName,
			orderDate,
			eventId,
			eventDate,
			price = 0,
			amountPaid = 0,
			isPaid,
			tel,
			...rest
		}) => [
			concatSchool({ schoolName, instName, name: deptName }),
			<SCLink module={SCModules.events} entryId={eventId} label={eventDate} />,
			new Date((orderDate || {})._seconds * 1000).toLocaleDateString('el-GR'),
			formatName(rest || {}),
			tel,
			`${Lang.currencySymbol.euro} ${price}`,
			`${Lang.currencySymbol.euro} ${price - amountPaid}`,
			<GenericActions
				id={id}
				view={'orders'}
				handleDelete={handleDelete && (() => setDeleteModal(id))}
				actions={[
					{
						label: 'Νέα Πληρωμή',
						Icon: AddMoneyIcon,
						onClick: () => setTxModal(id),
						disabled: isPaid,
						onComplete: () => setForceRefresh(true),
					},
				]}
			/>,
		]
	);

	const headers = [
		Lang.school,
		Lang.eventDate,
		Lang.orderDate,
		Lang.client,
		Lang.phoneNumber,
		Lang.price,
		Lang.amountOwed,
		'', //action buttons
	];

	return (
		<>
			<CustomTable
				pending={status === 'pending'}
				empty={status === 'success' && entries.length === 0}
				tableHead={headers}
				tableData={entries}
			/>
			<PaymentModal
				open={!!txModal}
				orderId={txModal}
				create
				onClose={() => setTxModal(undefined)}
			/>
			<DeleteModal
				open={!!deleteModal}
				onClick={() => handleDelete(deleteModal)}
				onClose={() => setDeleteModal(undefined)}
			>
				<OrderBrief
					{...((store || {})[deleteModal] || {})}
					orderId={deleteModal}
				/>
			</DeleteModal>
		</>
	);
}
