import React from 'react';
import TimeField from '../TimeField/TimeField';
import { Check, Edit } from '@material-ui/icons';
import Lang from '../../variables/i18n';
import Chip from '@material-ui/core/Chip';
import GenericActions from '../GenericActions';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import { CreateButton } from '../CommonButtons';
import CustomTable from '../Table/Table';
import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import UserAvatar from '../UserAvatar';

const StatusChip = ({ _published, _draft }) => (
	<Chip
		variant="outlined"
		icon={_draft ? <Edit /> : <Check />}
		label={
			_draft
				? Lang.draft
				: Date.now() > _published
				? 'Published'
				: 'Unpublished'
		}
	/>
);

export default function PostList({ showActions = false }) {
	const {
		store = {},
		status,
		handleDelete,
	} = useSupercore({
		supercoreApiName: SCModules.content,
	});
	const header = [
		'Title',
		'Created on',
		'Last Edited',
		'Published',
		'Author',
		'',
	];

	const entriesArray = asArray(store)
		.map(({ id, title, _author, _createdOnMS, _lastEditMS, _draft }) => [
			// slug,
			title,
			<TimeField datetime={_createdOnMS} />,
			<TimeField datetime={_lastEditMS} />,
			<StatusChip _draft={_draft} />,
			<UserAvatar uid={_author} />,
			showActions && (
				<GenericActions id={id} handleDelete={() => handleDelete(id)} edit />
			),
		])
		.reduce((acc, current) => acc.push(current) && acc, []);

	return (
		<Card>
			<CardHeader color="primary">
				<h3>Blog Entries</h3>
			</CardHeader>
			<CardBody>
				<CustomTable
					pending={status === 'pending'}
					empty={status === 'success' && entriesArray.length === 0}
					tableHead={header}
					tableData={entriesArray}
				/>
			</CardBody>
			<CardFooter>
				<CreateButton />
			</CardFooter>
		</Card>
	);
}
