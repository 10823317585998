import React from 'react';
import useSupercore, { asArray } from '../useSupercore';
import { SCModules } from '../supercore/api';
import UserAvatar from '../UserAvatar';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CustomTable from '../Table/Table';
import Lang from '../../variables/i18n';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Actions } from '../../store/store';

const TaskActions = ({ id, status, setStatus }) => (
	<>
		<Button
			disabled={status === 'done'}
			onClick={() =>
				setStatus(id, status === 'pending' ? 'started' : 'pending')
			}
		>
			{status === 'pending' ? 'Εκκινηση' : 'Παυση'}
		</Button>
		<Button
			disabled={status === 'pending'}
			onClick={() => setStatus(id, 'done')}
		>
			Ολοκληρωθηκε
		</Button>
	</>
);

export default function TasksTable({ parentId }) {
	const {
		store = {},
		status,
		dispatch,
	} = useSupercore({
		supercoreApiName: SCModules.tasks,
		entryId: `parentId/${parentId}`,
		debug: true,
	});

	const setStatus = async (id, status) =>
		dispatch({
			collection: SCModules.tasks,
			type: Actions.UPDATE_SINGLE,
			id,
			data: { ...store[id], status },
		});

	const entries = asArray(store)
		.sort((a = {}, b = {}) => (a.title || '').localeCompare(b.title || ''))
		.map(({ id, _lastEditBy, title, status }) => [
			_lastEditBy ? <UserAvatar uid={_lastEditBy} showName /> : null,
			title,
			status,
			<TaskActions id={id} status={status} setStatus={setStatus} />,
		]);

	return (
		<Card>
			<CardBody>
				<CustomTable
					pending={status === 'pending'}
					tableHead={[Lang.user, 'Εργασία', 'Κατάσταση', '']}
					tableData={entries}
				/>
			</CardBody>
		</Card>
	);
}

TasksTable.propTypes = {
	parentId: PropTypes.string.isRequired,
};
