import { ShoppingBasket, ViewModule } from '@material-ui/icons';
import React from 'react';
import Lang from '../../variables/i18n';
import { Navigate } from 'react-router';
import OrderDetails from '../../components/OrderDetails';
import { CreateButton } from '../../components/CommonButtons';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Card from '../../components/Card/Card';
import useSupercore from '../../components/useSupercore';
import { SCModules } from '../../components/supercore/api';
import OrdersTable from '../../components/OrdersTable';
import CardTitle from '../../components/CardTitle';

function OrdersView() {
	const {
		store = {},
		status,
		handleDelete,
	} = useSupercore({
		supercoreApiName: SCModules.orders,
	});

	return (
		<Card>
			<CardHeader>
				<CardTitle title={OrdersRoute.name} icon={<ViewModule />} />
			</CardHeader>
			<CardBody>
				<OrdersTable
					store={store}
					status={status}
					handleDelete={handleDelete}
				/>
			</CardBody>
			<CardFooter>
				<>
					<span />
					<CreateButton />
				</>
			</CardFooter>
		</Card>
	);
}

const OrdersRoute = {
	path: 'orders',
	name: Lang.orders,
	icon: ShoppingBasket,
	children: [
		{ path: '/', element: <OrdersView /> },
		{ path: 'create', element: <OrderDetails create /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:orderId', element: <OrderDetails /> },
		{ path: 'view/:orderId/:tab', element: <OrderDetails /> },
	],
};

export default OrdersRoute;
