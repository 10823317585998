import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import { hashCode } from '../../utils';

const colors = [
	'#e53935',
	'#d81b60',
	'#8e24aa',
	'#5e35b1',
	'#3949ab',
	'#1e88e5',
	'#039be5',
	'#00acc1',
	'#00897b',
	'#43a047',
	'#7cb342',
	'#c0ca33',
	'#fdd835',
	'#ffb300',
	'#fb8c00',
	'#f4511e',
];

const getInitials = ({ name, surname, displayName }) => {
	if (!name && !surname && !displayName) {
		return 'N/A';
	}

	name = !name || !name.length ? displayName.split(' ', 2)[0] : name;
	surname =
		!surname || !surname.length ? displayName.split(' ', 2).pop() : surname;

	return (name[0] + surname[0]).toUpperCase();
};
export default function UserAvatar({ uid, showName = false }) {
	const { store, status } = useSupercore({
		supercoreApiName: SCModules.users,
		entryId: uid,
	});

	const entry = (store || {})[uid] || {};
	let { displayName = '' } = entry;

	const AvatarIcon = () => (
		<Avatar
			style={{
				backgroundColor: colors[hashCode(uid) % colors.length],
			}}
		>
			{getInitials(entry)}
		</Avatar>
	);
	return status === 'pending' ? (
		<CircularProgress />
	) : showName ? (
		<Chip icon={<AvatarIcon />} label={displayName} />
	) : (
		<AvatarIcon />
	);
}
