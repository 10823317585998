import React, { useState } from 'react';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CustomInput from '../../components/CustomInput/CustomInput';
import TextEditor from '../TextEditor/TextEditor';
import CustomTabs from '../CustomTabs/CustomTabs';
import Lang from '../../variables/i18n';
import { useParams } from 'react-router';
import { CancelButton, CreateButton, SaveButton } from '../CommonButtons';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';

const initialData = { title: '', body: '', kind: 'blog' };

export default function ContentEditor({ create = false }) {
	const { postId } = useParams();
	const { store, handleSave, handleCreate } = useSupercore({
		supercoreApiName: SCModules.content,
		entryId: postId,
		create,
	});

	const [form, setForm] = useState(create ? initialData : store[postId] || {});

	return (
		<Card>
			<CardHeader>
				<CustomInput
					labelText="Title"
					id="text-title"
					value={form.title}
					onChange={(e) => setForm({ ...form, title: e.target.value })}
					formControlProps={{
						fullWidth: true,
					}}
				/>
			</CardHeader>
			<CardBody>
				<CustomTabs
					headerColor="primary"
					tabs={[
						{
							tabName: Lang.edit,
							tabContent: (
								<TextEditor
									body={form.body}
									onChange={(body) => setForm({ ...form, body })}
								/>
							),
						},
						{
							tabName: Lang.preview,
							tabContent: (
								<TextEditor
									body={form.body}
									onChange={(body) => setForm({ ...form, body })}
								/>
							),
						},
						{
							tabName: Lang.help,
							tabContent: <></>,
						},
					]}
				/>
			</CardBody>
			<CardFooter>
				<CancelButton />
				{create ? (
					<CreateButton onClick={() => handleCreate(form)} />
				) : (
					<SaveButton onClick={() => handleSave(form)} />
				)}
			</CardFooter>
		</Card>
	);
}
