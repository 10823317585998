import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';
import { customInputTableStyle } from '../../assets/jss/material-dashboard-react/components/customInputStyle';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(customInputTableStyle);

export default function ComboBox({
	id,
	label,
	placeholder,
	fullWidth,
	onChange,
	options,
	getOptionLabel,
	getOptionSelected,
	required = false,
	value,
	groupBy,
	renderOption,
	...props
}) {
	const classes = useStyles();
	return (
		<FormControl fullWidth={fullWidth} className={classes.formControl}>
			<InputLabel shrink>
				{required && <span style={{ color: 'red' }}>*</span>} {label}
			</InputLabel>
			<Autocomplete
				fullWidth={fullWidth}
				id={id}
				onChange={onChange}
				options={options}
				value={value}
				getOptionLabel={getOptionLabel}
				getOptionSelected={getOptionSelected}
				groupBy={groupBy}
				renderOption={renderOption}
				renderInput={(params) => (
					<TextField placeholder={placeholder} margin="normal" {...params} />
				)}
				{...props}
			/>
		</FormControl>
	);
}

ComboBox.propTypes = {
	id: PropTypes.string.isRequired,
	required: PropTypes.bool,
	label: PropTypes.any,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired,
	getOptionLabel: PropTypes.func,
	renderOption: PropTypes.func,
	getOptionSelected: PropTypes.func,
	groupBy: PropTypes.func,
};
