import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import GridItem from '../Grid/GridItem';
import { Dimension } from '../../variables/general';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardTitle from '../CardTitle';
import Lang from '../../variables/i18n';
import CardBody from '../Card/CardBody';
import GenericBackdrop, { useBackdrop } from '../GenericBackdrop';
import GenericForm from '../GenericForm';
import CardFooter from '../Card/CardFooter';
import { CancelButton, CreateButton, SaveButton } from '../CommonButtons';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import GridContainer from '../Grid/GridContainer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Person from '@material-ui/icons/Person';

const passwordValid = ({ password = '', passwordAgain = '' }) =>
	password === passwordAgain && password.length >= 8;

export default function UserDetails({ create = false }) {
	const { entryId: uid } = useParams();
	const [form, setForm] = useState({});

	const { status, handleSave, handleCreate, store } = useSupercore({
		create,
		supercoreApiName: SCModules.users,
		entryId: uid,
	});

	const [pending, setPending] = useBackdrop(status);

	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		!create && setForm(store[uid] || {});
	}, [create, setForm, store, uid, status]);

	const formFields = [
		{
			ld: 'l0',
			label: 'Λεπτομέρειες',
			type: 'formLabel',
		},
		{
			id: 'displayName',
			label: 'Display name',
			required: true,
		},
		{
			ld: 'l1',
			label: 'Σύνδεση',
			type: 'formLabel',
		},
		{
			id: 'email',
			placeholder: 'Χρησιμοποιείται κατα τη σύνδεση στο σαιτ',
			label: Lang.email,
			required: true,
		},
	];

	create &&
		formFields.push(
			{
				id: 'password',
				label: 'Προσωρινός κωδικός',
				required: true,
				type: 'password',
			},
			{
				id: 'passwordAgain',
				label: 'Επιβεβαίωση κωδικού',
				required: true,
				type: 'password',
			}
		);

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<CardHeader>
						<CardTitle
							id={uid}
							create={create}
							// handleDelete={handleDelete}
							createTitle={`Create ${Lang.user}`}
							editTitle={form.displayName}
							icon={<Person />}
						/>
					</CardHeader>
					<CardBody>
						<GenericBackdrop open={pending} />
						<GenericForm
							setFormState={setForm}
							formState={form}
							setIsValid={(valid) => setIsValid(valid && passwordValid(form))}
							fields={formFields}
						/>
					</CardBody>
					<CardFooter>
						<CancelButton />
						{create ? (
							<CreateButton
								disabled={!isValid}
								onPending={setPending}
								onClick={() => handleCreate(form)}
							/>
						) : (
							<SaveButton
								disabled={!isValid}
								onPending={setPending}
								onClick={() => handleSave(form)}
							/>
						)}
					</CardFooter>
				</Card>
			</GridItem>
			<GridItem {...Dimension.md}>
				<GenericAdditionalInfo show={!create} collection={'users'} id={uid} />
			</GridItem>
		</GridContainer>
	);
}
