import Lang from '../../variables/i18n';
import GenericForm from '../GenericForm';
import React from 'react';
import PropTypes from 'prop-types';
import OrderBrief from '../OrderBrief';

const TransactionForm = ({ form, setForm, orderId }) => {
	const { type } = form;

	return (
		<>
			<OrderBrief show={Boolean(orderId)} orderId={orderId} />
			<GenericForm
				setFormState={setForm}
				formState={form}
				fields={[
					{
						id: 'timestamp',
						label: Lang.datetime,
						disabled: true,
					},
					{
						id: 'amount',
						type: 'number',
						label: Lang.amount,
						min: 0,
						required: true,
					},
					{
						id: 'source',
						type: 'select',
						label: 'Πηγή',
						value: 'cash',
						required: true,
						options: [
							{
								label: 'Μετρητά',
								value: 'cash',
								selected: true,
							},
							{
								label: 'Κάρτα',
								value: 'card',
							},
						],
					},
					{
						id: 'shift',
						type: 'select',
						label: 'Βάρδια',
						required: true,
						options: [
							{
								label: 'Βάρδια 1',
								value: '1',
								selected: type === '1',
							},
							{
								label: 'Βάρδια 2',
								value: '2',
								selected: type === '2',
							},
						],
					},
					{
						id: 'type',
						label: 'Έσοδα / Έξοδα',
						type: 'radio',
						options: [
							{
								label: 'Έσοδα',
								value: 'in',
								selected: type === 'in',
								disabled: !!orderId,
							},
							{
								label: 'Έξοδα',
								value: 'out',
								selected: type === 'out',
								disabled: !!orderId,
							},
						],
					},
					{
						id: 'notes',
						label: 'Σημειώσεις',
						type: 'textarea',
					},
				]}
			/>
		</>
	);
};

TransactionForm.propTypes = {
	form: PropTypes.object,
	setForm: PropTypes.func,
};

export default TransactionForm;
