import React, { useState } from 'react';
import { School } from '@material-ui/icons';
import { Navigate } from 'react-router';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CustomTable from '../../components/Table/Table';
import Lang from '../../variables/i18n';
import CardFooter from '../../components/Card/CardFooter';
import { CreateButton } from '../../components/CommonButtons';
import Card from '../../components/Card/Card';
import useSupercore, { asArray } from '../../components/useSupercore';
import { SCModules } from '../../components/supercore/api';
import SchoolDetails from '../../components/SchoolDetails';
import GenericActions from '../../components/GenericActions';
import DeleteModal from '../../components/DeleteModal';
import { concatSchool } from '../../utils';
import CardTitle from '../../components/CardTitle';

const SchoolsView = () => {
	const [deleteModal, setDeleteModal] = useState(undefined);
	const { store, status, error, handleDelete } = useSupercore({
		supercoreApiName: SCModules.schools,
		initialData: [],
	});

	const entries = asArray(store).map(
		({
			id,
			name,
			schoolName,
			instName,
			shortName,
			ordersCount = 0,
			eventsCount = 0,
		}) => [
			instName,
			schoolName,
			name,
			shortName,
			eventsCount,
			ordersCount,
			<GenericActions
				id={id}
				view
				handleDelete={() => setDeleteModal(id)}
				deleteDisabled={ordersCount > 0 || eventsCount > 0}
			/>,
		]
	);

	return (
		<Card>
			<CardHeader>
				<CardTitle title={SchoolsRoute.name} icon={<School />} />
			</CardHeader>
			<CardBody>
				<CustomTable
					pending={status === 'pending'}
					empty={status === 'success' && entries.length === 0}
					error={error}
					tableHead={[
						'Ίδρυμα',
						'Σχολή',
						Lang.name,
						'Συντομονύμιο',
						'Αρ. Ορκομοσιών',
						'Αρ. Παραγγελιών',
						'', // actions toolbar
					]}
					tableData={entries}
				/>
			</CardBody>
			<CardFooter>
				<span />
				<CreateButton />
			</CardFooter>
			<DeleteModal
				open={!!deleteModal}
				onClick={() => handleDelete(deleteModal)}
				onClose={() => setDeleteModal(undefined)}
			>
				{deleteModal && concatSchool(store[deleteModal])}
			</DeleteModal>
		</Card>
	);
};

const SchoolsRoute = {
	path: 'schools',
	name: 'Σχολές & Ιδρύματα',
	icon: School,
	children: [
		{ path: '/', element: <SchoolsView /> },
		{ path: 'create', element: <SchoolDetails create /> },
		{ path: 'view', element: <Navigate to="../" /> },
		{ path: 'view/:eventId', element: <SchoolDetails /> },
	],
};

export default SchoolsRoute;
