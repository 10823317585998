import React from 'react';
import CustomTabs from '../CustomTabs/CustomTabs';
import PropTypes from 'prop-types';
import AuditHistory from '../AuditHistory';
import GenericComments from '../GenericComments';
import TransactionsTable from '../TransactionsTable';
import TasksTable from '../TasksTable';

/**
 * @return {null}
 */
export default function GenericAdditionalInfo({ collection, id, show }) {
	const tabs = [
		{
			tabName: 'Comments',
			tabContent: <GenericComments collection={collection} id={id} />,
		},
		{
			tabName: 'History',
			tabContent: <AuditHistory collection={collection} id={id} />,
		},
	];

	collection === 'orders' &&
		tabs.push(
			{
				tabName: 'Transactions',
				tabContent: (
					<TransactionsTable filter={{ field: 'orderId', value: id }} />
				),
			},
			{
				tabName: 'Εργασίες',
				tabContent: <TasksTable parentId={id} />,
			}
		);

	return !show ? null : <CustomTabs title="Additional info" tabs={tabs} />;
}

GenericAdditionalInfo.propTypes = {
	id: PropTypes.string.isRequired,
	collection: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
};
