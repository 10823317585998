import GenericForm from '../GenericForm';
import Lang from '../../variables/i18n';
import React from 'react';
import * as yup from 'yup';
import schoolEventPanel from '../SchoolEventPanel';
import { Dimension } from '../../variables/general';
import ItemsList from '../ItemsList';

export default function OrderForm({ create, form, setForm, setIsValid }) {
	const photoListId = 'photosList';

	const photoListOnClick = () => {
		const el = document.getElementById(photoListId);
		const val = (el.value || '').split(/\s+/).filter((s) => s.length);

		if (val.length === 0) {
			return;
		}

		setForm({
			...form,
			[photoListId]: [...new Set([...(form[photoListId] || []), ...val])],
		});

		el.value = '';
	};

	const photoListOnDelete = (item) => {
		setForm({
			...form,
			[photoListId]: [...form[photoListId].filter((t) => t !== item)],
		});
	};

	return (
		<React.Fragment>
			<h4>{Lang.details}</h4>
			<GenericForm
				setIsValid={setIsValid}
				formLabel={'Λεπτομέρειες παραγγελίας'}
				fields={[
					...schoolEventPanel({ form, setForm }),
					{
						id: 'price',
						label: `${Lang.price} €`,
						type: 'number',
						yup: yup.number().min(0).required(),
						required: true,
					},
					{
						id: 'orderDate',
						label: Lang.ordersView.paymentDetails.creationDate,
						type: 'datepicker',
						disabled: !create,
					},
					{
						id: 'photosList',
						size: Dimension.formItemFullWidth,
						renderElement: () => (
							<ItemsList
								id={photoListId}
								formState={form}
								setFormState={setForm}
								labelText={'Αριθμός φωτογραφίας'}
								emptyText={'Οι φωτογραφίες που προστίθενται θα φαίνονται εδώ'}
								onClick={photoListOnClick}
								onDelete={photoListOnDelete}
								copyToClipboard
							/>
						),
					},
					{
						id: 'fl1',
						type: 'formLabel',
						label: 'Στοιχεία πελάτη',
					},
					{
						id: 'firstName',
						label: 'Όνομα πελάτη',
						yup: yup.string().required(),
						required: true,
					},
					{
						id: 'lastName',
						label: 'Επώνυμο',
						yup: yup.string().required(),
						required: true,
					},
					{
						id: 'tel',
						label: 'Τηλέφωνο',
						yup: yup.string().required(),
						required: true,
					},
					{
						id: 'email',
						label: 'e-mail',
						type: 'email',
						yup: yup.string().email(),
					},
				]}
				formState={form}
				setFormState={setForm}
			/>
		</React.Fragment>
	);
}
