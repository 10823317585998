import React, { useContext } from 'react';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import routes from './../routes.js';
import styles from './../assets/jss/material-dashboard-react/layouts/adminStyle.js';
import { Card } from '@material-ui/core';
import CardHeader from '../components/Card/CardHeader';
import CardBody from '../components/Card/CardBody';
import CustomInput from '../components/CustomInput/CustomInput';
import AnonNavbar from '../components/Navbars/AnonNavbar';
import Button from './../components/CustomButtons/Button.js';
import CardFooter from '../components/Card/CardFooter';
import Check from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';
import EmailSharp from '@material-ui/icons/EmailSharp';
import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import { AppContext } from '../store/store';
import useAuthState, { actions } from '../store/auth';
import Lang from '../variables/i18n';
import { useNavigate } from 'react-router';
import SystemSettingsDefaults from '../variables/sysSettings';

const useStyles = makeStyles(styles);

export default function Register({ ...rest }) {
	const navigate = useNavigate();
	if (!SystemSettingsDefaults.allowSelfSignup) {
		navigate('/admin');
	}
	const classes = useStyles();
	const [state, dispatch] = useAuthState();
	const { firebase, setError } = useContext(AppContext);
	const {
		registerEmail,
		registerEmailValid,
		registerDisplayName,
		registerPass,
		registerPass2Valid,
		error,
	} = state;

	const handleRegister = async (e) => {
		e.preventDefault();

		if (error) {
			setError(error.message);
			return;
		}

		await firebase.registerNewUser(
			registerEmail,
			registerPass,
			registerDisplayName
		);
		dispatch({ type: actions.REGISTER_SUCCESS });
		navigate('/login');
	};
	return (
		<div className={classes.wrapper}>
			<div className={classes.mainPanel + ' unauthContainer'}>
				<AnonNavbar routes={routes} {...rest} />
				<Card className="registerCard">
					<div className="d-none" style={{ opacity: 0 }}>
						<input type="email" className="d-none" />
						<input type="password" className="d-none" />
					</div>
					<CardHeader>
						<h2>{Lang.register.header}</h2>
					</CardHeader>
					<CardBody>
						<CustomInput
							formControlProps={{
								className: '',
							}}
							inputProps={{
								id: 'displayName',
								autoComplete: 'please dont',
								placeholder: Lang.register.publicName,
								'aria-label': Lang.register.publicName,
								onInput: () => dispatch({ type: actions.UPDATE_REGISTER_FORM }),
								startAdornment: (
									<InputAdornment position="start">
										<Person />
									</InputAdornment>
								),
							}}
						/>

						<CustomInput
							error={!registerEmailValid}
							helperText={
								registerEmailValid && Lang.register.error.registerEmailValid
							}
							formControlProps={{
								className: '',
							}}
							inputProps={{
								id: 'email',
								type: 'email',
								placeholder: Lang.register.email,
								'aria-label': Lang.register.email,
								onInput: () => dispatch({ type: actions.UPDATE_REGISTER_FORM }),
								startAdornment: (
									<InputAdornment position="start">
										<EmailSharp />
									</InputAdornment>
								),
							}}
						/>

						<CustomInput
							formControlProps={{
								className: '',
							}}
							inputProps={{
								id: 'password',
								type: 'password',
								placeholder: Lang.register.password,
								'aria-label': Lang.register.password,
								onInput: () => dispatch({ type: actions.UPDATE_REGISTER_FORM }),
								startAdornment: (
									<InputAdornment position="start">
										<Lock />
									</InputAdornment>
								),
							}}
						/>

						<CustomInput
							type="password"
							error={!registerPass2Valid}
							helperText={
								registerPass2Valid && Lang.register.error.registerPass2Valid
							}
							formControlProps={{
								className: '',
							}}
							inputProps={{
								id: 'passwordAgain',
								type: 'password',
								placeholder: Lang.register.passwordAgain,
								'aria-label': Lang.register.passwordAgain,
								onInput: () => dispatch({ type: actions.UPDATE_REGISTER_FORM }),
								startAdornment: (
									<InputAdornment position="start">
										<Lock />
									</InputAdornment>
								),
							}}
						/>
					</CardBody>
					<CardFooter>
						<Button onClick={() => navigate('/login')}>
							<ArrowBack />
							<span className="btnTextIconSpacer">
								{Lang.register.backToLogin}
							</span>
						</Button>
						<Button onClick={(e) => handleRegister(e)}>
							<span className="btnTextIconSpacer">{Lang.register.join}</span>
							<Check />
						</Button>
					</CardFooter>
				</Card>
			</div>
		</div>
	);
}
