import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GridContainer from '../Grid/GridContainer';
import CustomInput from '../CustomInput/CustomInput';
import { Dimension } from '../../variables/general';
import GridItem from '../Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import { TimePickerEl } from '../DatePicker';
import Select from '@material-ui/core/Select';
import { DatePicker } from '@material-ui/pickers';
import { customInputTableStyle } from './../../assets/jss/material-dashboard-react/components/customInputStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import CustomFormControl from '../CustomFormControl';
import Lang from '../../variables/i18n';
import Checkbox from '@material-ui/core/Checkbox';
import * as yup from 'yup';
import CustomRadioGroup from '../CustomRadioGroup';
import UserPicker from '../UserPicker';

const useStyles = makeStyles(customInputTableStyle);

const FormItem = ({
	id,
	label,
	type = 'text',
	error = false,
	required = false,
	readOnly,
	disabled,
	placeholder,
	formState,
	setFormState,
	options,
	renderElement,
	shrink,
	...props
}) => {
	const classes = useStyles();
	const handleTimeUpdate = (timeStr) =>
		setFormState({ ...formState, [id]: timeStr });
	const handleFormUpdate = (e) =>
		setFormState({ ...(formState || {}), [id]: e.target.value });
	const handleToggleCheckbox = () =>
		setFormState({ ...(formState || {}), [id]: !formState[id] });

	if (typeof renderElement === 'function') {
		return renderElement();
	}

	label = required ? (
		<>
			<span style={{ color: 'red' }}>*</span> {label}
		</>
	) : (
		label
	);

	switch (type) {
		case 'formLabel':
			return <FormLabel>{label}</FormLabel>;
		case 'datepicker':
			return (
				<DatePicker
					required={required}
					className={classes.formControl}
					id={id}
					label={label || 'Ημερομηνία'}
					onChange={handleTimeUpdate}
					value={formState[id] || new Date()}
					readOnly={readOnly}
					disabled={disabled}
					animateYearScrolling
					autoOk
				/>
			);
		case 'timepicker':
			return (
				<CustomFormControl
					required={required}
					error={error}
					htmlFor={id}
					label={label || Lang.time}
				>
					<TimePickerEl
						id={id}
						showTodayButton
						todayLabel="Τωρα"
						margin="normal"
						minutesStep={5}
						value={formState[id] || ''}
						onChange={handleTimeUpdate}
					/>
				</CustomFormControl>
			);
		case 'radio':
			return (
				<CustomRadioGroup
					error={error}
					required={required}
					onChange={handleFormUpdate}
					options={options}
					label={label}
				/>
			);
		case 'checkbox':
			return (
				<CustomFormControl required={required} label={label} htmlFor={id}>
					<Checkbox
						id={id}
						onChange={handleToggleCheckbox}
						checked={formState[id] || false}
					/>
				</CustomFormControl>
			);
		case 'userpicker':
			return (
				<UserPicker
					id={id}
					onChange={handleFormUpdate}
					label={label}
					value={formState[id] || ''}
					{...props}
				/>
			);
		case 'select':
			return (
				<CustomFormControl error={error} label={label} htmlFor={id}>
					<Select
						id={id}
						data-testid={id}
						value={formState[id] || ''}
						onChange={handleFormUpdate}
					>
						{options.map(({ label, value, selected }) => (
							<MenuItem
								key={`${id}_${value}`}
								value={value}
								selected={selected || formState[id] === value}
							>
								{label}
							</MenuItem>
						))}
					</Select>
				</CustomFormControl>
			);
		default:
			return (
				<CustomInput
					labelText={label}
					type={type}
					onChange={handleFormUpdate}
					error={error}
					required={required}
					formControlProps={{
						fullWidth: true,
					}}
					labelProps={{ shrink }}
					inputProps={{
						id,
						'data-testid': id,
						type,
						value: formState[id] || '',
						readOnly,
						disabled,
						placeholder,

						multiline: type === 'textarea',
						rows: type === 'textarea' ? 4 : 1,
					}}
				/>
			);
	}
};

export default function GenericForm({
	fields,
	setFormState,
	formState,
	formLabel,
	setIsValid = () => {},
}) {
	const [errors, setErrors] = useState({});

	useEffect(() => {
		const schemaValidation = yup.object().shape(
			fields
				.filter(({ yup: yupObj }) => !!yupObj)
				.map(({ id, yup: yupObj }) => ({
					[id]: yupObj,
				}))
				.reduce((acc, curr) => ({ ...acc, ...curr }), {})
		);
		try {
			setErrors({});
			schemaValidation.validateSync(formState, { abortEarly: false });
		} catch ({ inner }) {
			setErrors(
				inner
					.map(({ path }) => path)
					.reduce((acc, curr) => (acc[curr] = true) && acc, {})
			);
		} finally {
			setIsValid(Object.keys(errors).length === 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields, formState]);

	return (
		<GridContainer spacing={4}>
			{formLabel && (
				<GridItem {...Dimension.formItemFullWidth}>
					<FormLabel>{formLabel}</FormLabel>
				</GridItem>
			)}
			{fields.map(
				({
					id,
					type,
					fullWidth = type === 'textarea',
					size = { ...Dimension.formItem },
					shrink,
					...props
				}) => (
					<GridItem
						key={`fi_${formLabel}_${id}`}
						{...(((fullWidth || type === 'formLabel') &&
							Dimension.formItemFullWidth) ||
							size)}
						gridItemClassName={
							type === 'formLabel' ? 'genericFormLabelRow' : ''
						}
					>
						<FormItem
							id={id}
							formState={formState}
							setFormState={setFormState}
							type={type}
							shrink={shrink}
							error={errors[id]}
							{...props}
						/>
					</GridItem>
				)
			)}
		</GridContainer>
	);
}

GenericForm.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.object).isRequired,
	setFormState: PropTypes.func.isRequired,
	formState: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
		.isRequired,
	formLabel: PropTypes.string,
	setIsValid: PropTypes.func,
};

FormItem.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	onUpdate: PropTypes.func,
	renderElement: PropTypes.func,
	error: PropTypes.bool,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	size: PropTypes.oneOf(Object.keys(Dimension)),
	options: PropTypes.array,
	yup: PropTypes.object,
	type: PropTypes.oneOf([
		'text',
		'number',
		'email',
		'select',
		'textarea',
		'password',
		'checkbox',
		'radio',
		'datepicker',
		'timepicker',
		'formLabel',
		'userpicker',
		'renderElement',
	]),
};
