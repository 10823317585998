import React, { Component, createContext, useReducer } from 'react';
import firebase from 'firebase';
import deepmerge from 'deepmerge';
import { SCModules } from '../components/supercore/api';

export const AppContext = createContext(null);
export const UserContext = createContext({ user: null });

class UserProvider extends Component {
	state = {
		user: null,
	};

	componentDidMount = () => {
		firebase.auth.onAuthStateChanged((userAuth) => {
			this.setState({ user: userAuth });
		});
	};

	render() {
		return (
			<UserContext.Provider value={this.state.user}>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}

export const Actions = {
	/**
	 * Clear all entries
	 */
	INIT_STORE: 'INIT_STORE',
	//Generic
	/**
	 * Update single entry in this collection
	 */
	UPDATE_SINGLE: 'UPDATE_SINGLE',
	/**
	 * Update multiple entries in this collection
	 */
	UPDATE_PAGE: 'UPDATE_PAGE',
	/**
	 * Delete entry in this collection
	 */
	DELETE: 'DELETE',

	//Forms
	/**
	 * Update field in form. Requires `fieldId`
	 */
	UPDATE_FORM: 'UPDATE_FORM',
	/**
	 * Clear form
	 */
	CLEAR_FORM: 'CLEAR_FORM',
	/**
	 * Set all fields of a form
	 */
	SET_FORM: 'SET_FORM',
};

function reducer(state, action) {
	const { type, data, collection, fieldId, id } = action;
	if (!collection && type !== Actions.INIT_STORE) {
		throw new Error('Missing field in action data: collection');
	}

	/*
	console.group(`Dispatch ${type} on ${collection}`);
	console.log(data);
	console.groupEnd();
*/

	const propertyFilterOn = collection === SCModules.history ? 'actionId' : 'id';

	switch (type) {
		case Actions.INIT_STORE:
			return {};
		case Actions.UPDATE_PAGE:
			if (!Array.isArray(data) || !data.length) {
				break;
			}
			return {
				...state,
				[collection]: {
					...state[collection],
					...data
						.map((entry) => ({ [entry[propertyFilterOn]]: entry }))
						.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
				},
			};
		case Actions.UPDATE_SINGLE:
			if (!id && collection === SCModules.users) {
				break;
			}

			return { ...deepmerge(state, { [collection]: { [id]: data } }) };

		case Actions.DELETE:
			if (!id) {
				throw new Error('Missing field in action data: id');
			}
			delete state[collection][id];
			return { ...state };

		case Actions.CLEAR_FORM:
			delete state.form[collection];
			return { ...state };
		case Actions.UPDATE_FORM:
			if (!fieldId) {
				throw new Error('Missing field in action data: fieldId');
			}
			return {
				...state,
				form: {
					...state.form,
					[collection]: { ...(state.form[collection] || {}), [fieldId]: data },
				},
			};
		// return newState;
		case Actions.SET_FORM:
			return { ...state, form: { ...state.form, [collection]: data } };
		default:
			return state;
	}

	return state;
}

export default UserProvider;

export const useInitialiseStore = () => {
	const [state, dispatch] = useReducer(reducer, {});

	return { state, dispatch };
};
