import React from 'react';
import GenericForm from '../GenericForm';
import { Dimension } from '../../variables/general';
import { genericAddressFields } from '../GenericAddressForm';

export default function OrderDeliveryForm({ formState, setFormState }) {
	const { isDelivery } = formState;
	const addressFields = genericAddressFields.map((field) => ({
		...field,
		disabled: !isDelivery,
		required: isDelivery,
	}));

	return (
		<GenericForm
			setFormState={setFormState}
			formState={formState}
			formLabel={'Αντικαταβολή και Διεύθυνση'}
			fields={[
				{
					id: 'isDelivery',
					type: 'checkbox',
					label: 'Αντικαταβολή',
					size: Dimension.formItemFullWidth,
				},
				...addressFields,
				{
					id: 'trackingId',
					label: 'Tracking number',
				},
			]}
		/>
	);
}
