import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { getInitials } from '../../layouts/utils';
import TimeField from '../../components/TimeField/TimeField';
import Lang from '../../variables/i18n';
import Chip from '@material-ui/core/Chip';
import { asUserArray } from '../../components/useSupercore';
import CustomTable from '../../components/Table/Table';
import GenericActions from '../../components/GenericActions';
import PersonAdd from '@material-ui/icons/PersonAdd';

export default function UsersTable({ status, store = {} }) {
	const usersArray = asUserArray(store)
		.map(({ uid, displayName, createdAt, disabled, ...args }) => [
			<Avatar>{getInitials({ displayName, ...args })}</Avatar>,
			displayName,
			<TimeField datetime={createdAt} />,
			<Chip
				label={disabled ? Lang.inactive : Lang.active}
				variant="outlined"
				color={disabled ? 'default' : 'primary'}
			/>,
			<GenericActions
				id={uid}
				view={'users'}
				actions={[
					{
						label: 'Πρόσκληση',
						Icon: PersonAdd,
						onClick: () => {},
					},
				]}
			/>,
		])
		.reduce((acc, curr) => acc.push(curr) && acc, []);

	return (
		<>
			<CustomTable
				tableHead={['', Lang.displayName, Lang.memberSince, Lang.status, '']}
				tableData={usersArray}
				pending={status === 'pending'}
				empty={status === 'success' && usersArray.length === 0}
			/>
		</>
	);
}
