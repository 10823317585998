import React, { useState } from 'react';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardFooter from '../Card/CardFooter';
import Lang from '../../variables/i18n';
import { useParams } from 'react-router';
import { BackButton, CreateButton, SaveButton } from '../CommonButtons';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import { ShoppingBasketOutlined, ViewModule } from '@material-ui/icons';
import OrderForm from '../OrderForm';
import CardBody from '../Card/CardBody';
import OrderDeliveryForm from '../OrderDeliveryForm';
import OrderExtrasForm from '../OrderExtrasForm';
import GenericBackdrop, { useBackdrop } from '../GenericBackdrop';
import CardTitle from '../CardTitle';
import { formatName } from '../../utils';
import {
	ChipContainer,
	OrderCompleteChip,
	OrderTasksChip,
	PaymentChip,
} from '../CommonChips';
import { AddMoneyIcon } from '../../variables/AppIcons';
import PaymentModal from '../PaymentModal';
import GridContainer from '../Grid/GridContainer';
import { Dimension } from '../../variables/general';
import GridItem from '../Grid/GridItem';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import useForceRefresh from '../useForceRefresh';

const InitialData = { orderDate: new Date() };
const parseIncomingData = ({ orderDate = {}, ...rest } = {}) => {
	rest.orderDate = new Date(orderDate._seconds * 1000);
	return rest;
};

const makeTitle = ({ lastName }) => formatName({ lastName });

export default function OrderDetails({ create = false }) {
	const { orderId: entryId } = useParams();
	const [txModal, setTxModal] = useState(undefined);
	const [form, setForm] = useState(InitialData);
	const {
		status,
		//handleCreate creates tasks. handleSave doesn't touch them
		handleCreate,
		handleSave,
		handleDelete,
	} = useSupercore({
		create,
		supercoreApiName: SCModules.orders,
		entryId,
		setForm: (data) => setForm(parseIncomingData(data)),
	});

	const [pending, setPending] = useBackdrop(status);
	const { setForceRefresh } = useForceRefresh();

	const handleCreateOrderAndTasks = async () => {
		const { phbSize } = form;
		form.hasPhotobook = phbSize && phbSize !== 'none';
		await handleCreate(form, false);
	};

	const { isPaid } = form;

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<GenericBackdrop open={pending} />
					<CardHeader>
						<CardTitle
							id={entryId}
							create={create}
							handleDelete={handleDelete}
							onDeleteComplete={() => setForceRefresh(true)}
							createTitle={Lang.ordersView.createOrderTitle}
							editTitle={`${Lang.ordersView.viewOrderTitle} ${makeTitle(form)}`}
							icon={<ShoppingBasketOutlined />}
							isDeleted={form._isDeleted}
							actions={[
								{
									label: 'Νέα Πληρωμή',
									Icon: AddMoneyIcon,
									onClick: () => setTxModal(entryId),
									disabled: isPaid,
									onComplete: () => setForceRefresh(true),
								},
							]}
						/>
						<ChipContainer show={!create}>
							<OrderCompleteChip {...form} />
							<PaymentChip {...form} />
							<OrderTasksChip {...form} />
						</ChipContainer>
					</CardHeader>
					<CardBody>
						<OrderForm create={create} form={form} setForm={setForm} />
						<OrderExtrasForm
							formState={form}
							setFormState={setForm}
							create={create}
						/>
						<OrderDeliveryForm formState={form} setFormState={setForm} />
					</CardBody>
					<CardFooter>
						<BackButton />
						{create ? (
							<CreateButton
								icon={<ViewModule />}
								onPending={setPending}
								onClick={() => handleCreateOrderAndTasks(form)}
							/>
						) : (
							<SaveButton
								onPending={setPending}
								onClick={() => handleSave(form)}
							/>
						)}
					</CardFooter>
					<PaymentModal
						open={!!txModal}
						orderId={txModal}
						create
						onClose={() => setTxModal(undefined)}
					/>
				</Card>
			</GridItem>
			<GridItem {...Dimension.md}>
				<GenericAdditionalInfo
					show={!create}
					collection={'orders'}
					id={entryId}
				/>
			</GridItem>
		</GridContainer>
	);
}
