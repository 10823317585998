import GridItem from '../Grid/GridItem';
import { Dimension } from '../../variables/general';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import Lang from '../../variables/i18n';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import { CancelButton, CreateButton, SaveButton } from '../CommonButtons';
import { Event } from '@material-ui/icons';
import GenericAdditionalInfo from '../GenericAdditionalInfo';
import GridContainer from '../Grid/GridContainer';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import useSupercore from '../useSupercore';
import { SCModules } from '../supercore/api';
import TransactionForm from '../TransactionForm';

export const TxInitialData = {
	timestamp: new Date(),
	type: 'in',
	source: 'cash',
	shift: new Date().getHours() >= 13 ? '2' : '1',
};

export default function TransactionDetails({ create = false }) {
	const { transactionId } = useParams();
	const [form, setForm] = useState(TxInitialData);
	const { status, handleCreate, handleSave } = useSupercore({
		create,
		supercoreApiName: SCModules.vault,
		entryId: transactionId,
		setForm,
	});

	const { orderId } = form;

	return (
		<GridContainer>
			<GridItem {...Dimension.md}>
				<Card>
					<CardHeader>
						<h4>{Lang.transactionDetails.title}</h4>
					</CardHeader>
					<CardBody>
						<TransactionForm
							transactionId={transactionId}
							create={create}
							orderId={orderId}
							form={form}
							setForm={setForm}
						/>
					</CardBody>
					<CardFooter>
						<CancelButton />
						{create ? (
							<CreateButton
								icon={<Event />}
								disabled={status === 'pending'}
								onClick={() => {
									handleCreate(form);
								}}
							/>
						) : (
							<SaveButton
								disabled={status === 'pending'}
								onClick={() => {
									handleSave(form);
								}}
							/>
						)}
					</CardFooter>
				</Card>
			</GridItem>
			{!create && (
				<GridItem {...Dimension.md}>
					<GenericAdditionalInfo
						collection={'transactions'}
						id={transactionId}
					/>
				</GridItem>
			)}
		</GridContainer>
	);
}
