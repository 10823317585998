import { createContext, useContext, useEffect, useMemo } from 'react';
import { SCModuleAPI, useAsync } from '../supercore/util';
import { useNavigate } from 'react-router';
import { Actions } from '../../store/store';

export const StoreContext = createContext({ form: {} });

export const asArray = (storeData) =>
	Object.getOwnPropertyNames(storeData || {}).map((id) => storeData[id]);

export const asUserArray = (storeData) =>
	Object.getOwnPropertyNames(storeData || {}).map((uid) => storeData[uid]);

export const useStore = () => {
	const { state, dispatch } = useContext(StoreContext);
	return { state, dispatch };
};

/**
 *
 * @param {boolean} create
 * @param {boolean} refreshData
 * @param {string} supercoreApiName
 * @param {string?} entryId
 * @param {function?} setPending
 * @param {function?} setForm
 * @param {boolean?} debug Show debug information
 * @returns {{globalState: *, handleSave: Function, store: *, error: *, value: *, status: *, handleCreate: Function, handleDelete: Function}}
 */
export default function useSupercore({
	create = false,
	refreshData = true,
	supercoreApiName,
	entryId,
	setForm,
	debug = false,
	// filter,
}) {
	const { state: store, dispatch } = useStore();
	const navigate = useNavigate();
	const api = useMemo(
		() => SCModuleAPI({ module: supercoreApiName, params: { path: entryId } }),
		[supercoreApiName, entryId]
	);

	const { status, value, error } = useAsync(api.get);

	!create &&
		refreshData &&
		value &&
		!value.bodyUsed &&
		value
			.text()
			.then((raw) => (raw ? JSON.parse(raw) : {}))
			.then((response) => {
				const { data } = response;

				dispatch(
					entryId && entryId.indexOf('/') === -1
						? {
								collection: supercoreApiName,
								type: Actions.UPDATE_SINGLE,
								id: entryId,
								data: response,
						  }
						: {
								collection: supercoreApiName,
								type: Actions.UPDATE_PAGE,
								data: data || response,
						  }
				);
			})
			.catch((e) => {
				console.error(e);
			});

	if (debug) {
		console.group(`Context.${supercoreApiName}`);
		console.log(store);
		console.groupEnd();
	}

	useEffect(() => {
		if (create || typeof setForm !== 'function') {
			return;
		}

		setForm(store[supercoreApiName][entryId] || {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [create, entryId, supercoreApiName, store, entryId]);

	return {
		store: store[supercoreApiName] || {},
		globalState: store,
		status,
		value,
		error,
		dispatch,
		handleSave: async (data, goBack = false) => {
			console.log(`${supercoreApiName} handleSave`);
			try {
				const { data: responseData } = await (
					await api.update(entryId, data)
				).json();
				goBack && navigate('../');
				return responseData;
			} catch (e) {
				console.warn(e);
			}
		},
		handleCreate: async (data, goBack = false, disableNav = false) => {
			try {
				const { data: resData } = await (await api.create(data)).json();
				const { id: responseId } = resData || {};

				dispatch({
					type: 'UPDATE_SINGLE',
					collection: supercoreApiName,
					id: responseId,
					data: resData,
				});

				if (!disableNav && responseId) {
					goBack ? navigate('../') : navigate(`../view/${responseId}`);
				}

				return responseId;
			} catch (e) {
				console.warn(e);
			}
		},
		handleDelete: async (id) => {
			try {
				await api.delete(id);
				dispatch({ type: Actions.DELETE, id });
			} catch (e) {
				console.warn(e);
			}
		},
	};
}
